import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
// import infoIcon from "../../../assets/info-black-icon.svg";
import "../../../styles/dashboard/pos/RequestPos.css";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
// import PreviewRequestPosDetailsBox from "./PreviewRequestPosBox";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import { filterEmptyColumns, formatNumWithCommaNairaSymbol, reactSelectStyle } from "../../../utils/helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getStates, getLgaByStates } from "../../../redux/verifications";
// import TerminalTransactionPin from "./modal/TerminalTransactionPin";andriod-pos-img.png
import SuccessModal from "../../../components/reusables/SuccessModal";
import { requestPos } from "../../../redux/pos";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import BankBOxImg from "../../../assets/Bank_box_img.png";
// import AndriodPosImg from "../../../assets/andriod-pos-img.png";
// import { FaCheck } from "react-icons/fa";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import ActionContext from "../../../context/ActionContext";

const PosRequestTerminal = () => {
  const { loading } = useSelector((state) => state.pos);
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState({  
    pin: false,
    success: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { states, Lgas } = useSelector((state) => state.verifications);
  const stepList = [
    "BankBox details",
    "Request details",
    "Shipping details",
    "Confirm Information",
  ];
  const [step, setStep] = useState(1);

  const [details, setDetails] = useState({
    label: "",
    quantity: "1",
    beneficiary: false,
    reason: "",
    state: "",
    lga: "",
    house_no: "",
    adress: "",
    pin: "",
    completePin: false,
    landmark: "",
    terminal_type: "BankBox (Card Reader)",
  });

  const terminalListType = [
    {
      img: BankBOxImg,
      name: "BankBox (Card Reader)",
      subtitle: "all you need to know about BankBox ",
      detail: [
        "Operated with a OTG cord or bluetooth.",
        `Caution fee of ${formatNumWithCommaNairaSymbol(20000)}`,
        "Easy connection with smartphone device",
        "Compatible with all android and ios device",
        "Easy to carry around",
      ],
    },
    // {
    //   img: AndriodPosImg,
    //   name: "Android POS",
    //   subtitle: "all you need to know about android POS ",
    //   detail: [
    //     "Long lasting battery life",
    //     `Caution fee of ${formatNumWithCommaNairaSymbol(20000)}`,
    //     "Strong and reliable connection",
    //     "Comes with own account number.",
    //     "Easy to carry around",
    //   ],
    // },
  ];   

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  useEffect(() => {
    dispatch(getStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitDetails = async (pin, token) => {
    const obj = {
      quantity: details?.quantity,
      delivery_address: `${details?.house_no} ${details?.adress}`,
      state: details?.state?.label,
      lga: details?.lga?.label,
      reason: details?.reason,
      pos_pin: pin,
      two_factor_token: token,
      landmark: details?.landmark,
      pos_request_type:
        details?.terminal_type === "BankBox (Card Reader)" ? "bankbox" : "standard",
    };
    // console.log(obj);
    // return
    const data = await dispatch(requestPos(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setDetails({
      label: "",
      quantity: "1",
      beneficiary: false,
      reason: "",
      state: "",
      lga: "",
      house_no: "",
      adress: "",
      pin: "",
      completePin: false,
      terminal_type: "BankBox (Card Reader)",
    });
    setShowModal((prev) => {
      return { ...prev, pin: false, success: true };
    });
  };

  const size = useWindowSize();

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Request for a BankBox`}
          text={`Ready to be a bank to the less banked people, request for BankBox now.`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        <ContainerWrapper
          width={size.width < 900 && `100%`}
          stepList={stepList}
          activeStep={step}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          btnText={
            step === 1
              ? `Request details`
              : step === 2
              ? `Next shipping details`
              : `Confirm details`
          }
          stepClickable={
            details?.label &&
            details?.reason &&
            details?.quantity &&
            details?.adress &&
            details?.house_no &&
            details?.lga &&
            details?.landmark &&
            details?.state
          }
          onStepClick={(e) => {
            details?.label &&
              details?.reason &&
              details?.quantity &&
              details?.adress &&
              details?.house_no &&
              details?.lga &&
              details?.landmark &&
              details?.state &&
              setStep(e);
          }}
          disabled={
            step === 1
              ? !details?.terminal_type
              : step === 2
              ? !details?.label || !details?.reason || !details?.quantity
              : step === 3
              ? !details?.label ||
                !details?.reason ||
                !details?.quantity ||
                !details?.adress ||
                !details?.house_no ||
                !details?.lga ||
                !details?.landmark ||
                !details?.state
              : false
          }
          onBtnClick={() => {
            step === 1 && setStep(2);
            step === 2 && setStep(3);
            step === 3 && setStep(4);
            if(step === 4){
              if (user?.pin_status === 0) {
                actionCtx.setCheckShow("nine");
              } else {
                setShowModal((prev) => {
                  return { ...prev, pin: true };
                });
              }
            }
            // step === 4 &&
            //   setShowModal((prev) => {
            //     return { ...prev, pin: true };
            //   });
          }}
        >
          {/* request pos wrap start */}
          <div className="request-pos-wrap-box">
            {/* step one start-------------------------- */}
            {step === 1 && (
              <>
                <div className="select-pos-request-type-box">
                  {/* <p className="select-title">Select Terminal Type</p> */}
                  <div className="img-select-wrap">
                    {" "}
                    {terminalListType.map((chi, idx) => {
                      const { img, name } = chi;
                      return (
                        <React.Fragment key={idx}>
                          <div
                            // key={idx}
                            className={`img-wrap grey-bg select-option-class 
                           
                             `}
                            style={{gridColumn: "1 / -1",}}
                            onClick={() => {
                              setDetails((prev) => {
                                return { ...prev, terminal_type: name };
                              });
                            }}
                          >
                            {/* <div className="check-box">
                              <FaCheck className="icon" />
                            </div> */}
                            <figure className="img-box">
                              <img className="img" src={img} alt="" />
                            </figure>
                            <p
                              style={{
                                opacity:
                                  details?.terminal_type !== name ? 0.6 : 1,
                              }}
                              className={`name ${
                                details?.terminal_type !== name &&
                                "grey-white-color"
                              }`}
                            >
                              {name}
                            </p>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {terminalListType?.map((chi, idx) => {
                    const { detail, name, subtitle } = chi;
                    if (details?.terminal_type === name) {
                      return (
                        <div className="description-box-wrap yellow-grey-dark-bg grey-white-color">
                          <div className="icon-subtitle">
                            <div className="img-wrap">
                              <figure className="img-box">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="img"
                                >
                                  <path
                                    d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                    stroke="#EA872D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </figure>
                            </div>
                            <p className="sub-title">{subtitle}</p>
                          </div>
                          {/* list start */}
                          <div className="list-box">
                            {detail?.map((chi, idx) => {
                              return (
                                <div key={idx} className="list-item">
                                  <div className="black-fade-white-bg"></div>
                                  <p className="text">{chi}</p>
                                </div>
                              );
                            })}
                          </div>
                          {/* list end */}
                        </div>
                      );
                    }
                    return <React.Fragment key={idx}></React.Fragment>;
                  })}
                </div>
              </>
            )}
            {/* step one end-------------------------- */}
            {/* step two start ------------------------ */}
            {step === 2 && (
              <>
                {/* download info box start */}
                {/* <div className="download-info-box grey-bg">
                  <div className="text-icon-box">
                    <figure className="img-box">
                      <img src={infoIcon} alt="" className="img" />
                    </figure>
                    <p className="text grey-white-color">
                      Note on requesting Terminal{" "}
                    </p>
                  </div>
                  <div className="dot-text">
                    <p className="dot grey-white-color">•</p>
                    <p className="text grey-white-color">
                      Terminal request comes with a caution fee of{" "}
                      <span>N20,000.00 </span>
                    </p>
                  </div>
                  <div className="dot-text">
                    <p className="dot grey-white-color">•</p>
                    <p className="text grey-white-color">
                      Delivery fee of terminal is dependent on location and
                      urgency of delivery (charged from account balance)
                    </p>
                  </div>
                  <div className="dot-text">
                    <p className="dot grey-white-color">•</p>
                    <p className="text grey-white-color">
                      Deliveries would take 72 hours or less and with a fee.
                    </p>
                  </div>
                </div> */}
                {/* download info box end */}
                <div className="form-input-wrap">
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    <RavenInputField
                      color={`black-light`}
                      type={`text`}
                      label={`BankBox label* `}
                      placeholder={`e.g Netflix subscription card`}
                      name={`label`}
                      value={details?.label}
                      onChange={handleChange}
                      capitalize
                    />
                  </form>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      type={`number`}
                      label={`BankBox Quantity* `}
                      placeholder={`E.g 3`}
                      name={`quantity`}
                      value={details?.quantity}
                      onChange={handleChange}
                    />
                  </form>

                  {/* <RavenInputField
                    color={`black-light`}
                    type={`select`}
                    label={`Reason for requesting* `}
                    
                    menuPlacement={`top`}   
                    selectStyles={reactSelectStyle}
                    placeholder={`Dropdown Option`}
                    selectOption={selectOption}
                    name={`reason`}
                    style={{position: "relative", zIndex: "10"}}
                    value={details?.reason}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, reason: e };
                      });
                    }}
                  /> */}
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      type={`text`}
                      label={`Reason for requesting* `}
                      placeholder={`Dropdown Option`}
                      name={`reason`}
                      value={details?.reason}
                      onChange={handleChange}
                      capitalize
                    />
                  </form>
                </div>
              </>
            )}
            {/* step two end ------------------------ */}
            {/* step three start ------------------------ */}
            {step === 3 && (
              <>
                <div className="mascot-description-box grey-bg"></div>
                <div className="form-input-wrap">
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      type={`select`}
                      label={`State of resident*  `}
                      selectStyles={reactSelectStyle}
                      placeholder={`Select a state.`}
                      name={`state`}
                      value={details?.state}
                      onChange={(e) => {
                        dispatch(getLgaByStates(e.value));
                        setDetails((prev) => {
                          return { ...prev, state: e, lga: "" };
                        });
                      }}
                      selectOption={states}
                      style={{ position: "relative", zIndex: "17" }}
                    />
                  </form>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      selectStyles={reactSelectStyle}
                      type={`select`}
                      label={`Local government* `}
                      placeholder={`Select a local government`}
                      name={`state`}
                      value={details?.lga}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, lga: e };
                        });
                      }}
                      selectOption={Lgas}
                      style={{ position: "relative", zIndex: "10" }}
                    />
                  </form>

                  <div className="two-grid-box">
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      <RavenInputField
                        color={`black-light`}
                        type={`text`}
                        label={`House No* `}
                        placeholder={`e.g 120`}
                        name={`house_no`}
                        value={details?.house_no}
                        onChange={handleChange}
                        capitalize
                      />
                    </form>
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        color={`black-light`}
                        type={`text`}
                        label={`House address* `}
                        placeholder={`Name of street `}
                        name={`adress`}
                        value={details?.adress}
                        onChange={handleChange}
                        capitalize
                      />
                    </form>
                  </div>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      color={`black-light`}
                      type={`text`}
                      label={`Landmark* `}
                      placeholder={`Name of street `}
                      name={`landmark`}
                      value={details?.landmark}
                      capitalize
                      onChange={handleChange}
                    />
                  </form>
                </div>
              </>
            )}
            {/* step three end ------------------------ */}
            {step === 4 && (
              <>
                {/* <PreviewRequestPosDetailsBox
                  adress={details?.adress}
                  reason={details?.reason}
                  quantity={details?.quantity}
                  state={details?.state?.label}
                  lga={details?.lga?.label}
                  landmark={details?.landmark}
                /> */}
                <NewPreviewDetailBox
                  adress={details?.adress}
                  reason={details?.reason}
                  quantity={details?.quantity}
                  state={details?.state?.label}
                  lga={details?.lga?.label}
                  landmark={details?.landmark}
                  type={details?.terminal_type}
                />
              </>
            )}
            {/* step three end ------------- */}
          </div>
          {/* request pos wrap end */}
        </ContainerWrapper>
      </DashboardLayout>
      {/* pin modal start */}
      {/* <TransactionPinModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
      /> */}
      {/* pin modal end */}
      {/* pin modal start */}
      {/* <TerminalTransactionPin
        detailsTwo={details}
        visible={showModal.pin}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, pin: false };
          })
        }
        onFinish={handleFinish}
      /> */}
      {/* pin modal end */}

      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          handleSubmitDetails(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}

      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Terminal Request Successful."
        text={`Thank you for using our services. If you have any questions or concerns, please don't hesitate to contact us. We appreciate your business and look forward to serving you again in the future.`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
          navigate("/dashboard-pos-management");
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default PosRequestTerminal;
