import React, { useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import {
  RavenButton,
  RavenInputField,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../../assets/delete-icon.svg";
import { ThreeDots } from "react-loader-spinner";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import {
  deletePendingTeamMember,
  getTeamMembers,
  getTeamRoles,
  // inviteTeamMember,
  retryInviteTeamMember,
  updateTeamRole,
} from "../../../../redux/settings";
import {
  formatTypeFunction,
  lowerCaseWrap,
  reactSelectStyle,
  SPECIAL_ROLE_NAME,
} from "../../../../utils/helper/Helper";
import TeamInfoModal from "../modal/TeamInfoModal";
import DeleteTeamModal from "../modal/DeleteTeamModal";

const DashboardSettingsTeams = () => {
  const [singleChild, setSingleChild] = useState({});
  // const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    team_invite: false,
    team_delete: false,
    device_delete: false,
    statement: false,
    electricity: false,
    view_directors: false,
    delete_sub_account: false,
    change_primary: false,
    remove_role_account: false,
    add_role_account: false,
  });
  const [refreshTable] = useState(false);
  const dispatch = useDispatch();
  const [singleObj, setSingleObj] = useState({});
  const { teamMembers, loading, teamRoles } = useSelector(
    (state) => state.settings
  );
  const { user, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  const [teamMemberList, setTeamMemberList] = useState([]);

  useEffect(() => {
    handleGetTeamMembers();
    dispatch(getTeamRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const handleGetTeamMembers = async () => {
    const data = await dispatch(getTeamMembers());
    if (data?.payload?.status === "success") {
      const list = data?.payload?.data?.processed_team_members;
      setTeamMemberList(list);
    }
  };

  const handleEditRole = async (e, chi) => {
    // console.log(id);
    const obj = {
      email: chi?.user_email,
      role: e.value,
    };
    const data = await dispatch(updateTeamRole(obj));
    if (data?.payload?.data?.status === "success") {
      const newList = teamMemberList.map((child) =>
        child?.id === chi?.id ? { ...child, clearance: e.value } : child
      );
      setTeamMemberList(newList);
    }
  };

  const formatRoleOption = (list) => {
    if (list?.length > 0) {
      // console.log(list);
      const listOne = list?.filter((chi) => chi?.label !== SPECIAL_ROLE_NAME);
      const newList = listOne?.map((chi) => {
        return { ...chi, label: lowerCaseWrap(chi?.label) };
      });
      return newList;
    }
  };

  const rerrangeListFunc = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const sortedArray = [...list]?.sort((a, b) => {
        if (a?.confirmation_status === b?.confirmation_status) {
          return 0;
        }
        return a?.confirmation_status === 1 ? -1 : 1;
      });

      // console.log(sortedArray);
      return sortedArray;
    }
  };

  const retryIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16935_15530)">
        <path
          className="dull-bright-filter"
          d="M8.63812 15.1125L6.65436 13.1288M6.65436 13.1288L8.63812 11.145M6.65436 13.1288H9.96063C12.517 13.1288 14.5894 11.0564 14.5894 8.5C14.5894 6.65351 13.5082 5.05955 11.9444 4.31666M4.00934 12.6833C2.44551 11.9404 1.36432 10.3465 1.36432 8.5C1.36432 5.94359 3.4367 3.87122 5.9931 3.87122H9.29938M9.29938 3.87122L7.31561 1.88745M9.29938 3.87122L7.31561 5.85498"
          stroke="#020202"
          strokeWidth="1.23434"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16935_15530">
          <rect
            width="15.8701"
            height="15.8701"
            fill="white"
            transform="translate(0.0418091 0.564941)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const [loadingRetry, setLoadingRetry] = useState(false);
  const handleSubmitRetry = async (param) => {
    const obj = {
     ref: param?.reference
    };
    setLoadingRetry(true);
    const data = await dispatch(retryInviteTeamMember(obj));
    if (data?.payload?.data?.status === "success") {
      setLoadingRetry(false);
      // dispatch(getTeamMembers());
      setSingleObj({});
    } else {
      setLoadingRetry(false);
    }
  };

  const handleDeletePending = async (param) => {
    const obj = {
      reference: param?.reference,
    };
    setLoadingRetry(true);
    const data = await dispatch(deletePendingTeamMember(obj));
    if (data?.payload?.data?.status === "success") {
      setLoadingRetry(false);
      dispatch(getTeamMembers());
      setSingleObj({});
    } else {
      setLoadingRetry(false);
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        team_invite: false,
        team_delete: false,
        device_delete: false,
        statement: false,
        electricity: false,
        view_directors: false,
        delete_sub_account: false,
        change_primary: false,
        remove_role_account: false,
        add_role_account: false,
      };
    });
  };

  const arrangeListFunc = (list) => {
    if (list?.length > 0) {
      const targetValue = SPECIAL_ROLE_NAME;
      //   console.log(list);
      // Filter out the object with the specified value
      const ownerObject = list.find((obj) => obj.clearance === targetValue);
      const filteredObjects = list.filter(
        (obj) => obj.clearance !== targetValue
      );

      // Add the ownerObject to the beginning of the array
      const newArray = ownerObject ? [ownerObject, ...filteredObjects] : list;
      return newArray;
    }
  };

  return (
    <>
      <DashboardSettingsLayout>
        <div className="detail-wrap animate-move-up-class" id="Teams">
          <div className="text-btn-box">
            {" "}
            <div
              onClick={() => {
                // console.log(teamMembers);
              }}
              className="text-wrap"
            >
              <p className="title">Team Management</p>
              <p className="sub-title grey-white-color">
                Manage your team and invite your .
              </p>
            </div>
            {user_resource_and_permissions?.allowed_permissions?.includes(
              "can_invite_new_team_member"
            ) && (
              <RavenButton
                color={`black-light`}
                label={`Invite new member`}
                onClick={() => {
                  setShowModal((prev) => {
                    return { ...prev, team_invite: true };
                  });
                }}
              />
            )}
          </div>

          <div
            // onClick={() => {
            //   console.log(teamMemberList);
            // }}
            style={{ minHeight: "45rem" }}
            className="box-wrap grey-bg"
          >
            {/* noti wrap start */}
            {/* team member start */}
            {teamMembers?.pending_team_invites?.length > 0 ||
            teamMemberList?.length > 0 ? (
              <>
                {arrangeListFunc(rerrangeListFunc(teamMemberList))?.map(
                  (chi, idx) => {
                    // console.log(chi);
                    const {
                      fname,
                      lname,
                      user_email,
                      clearance,
                      confirmation_status,
                    } = chi;
                    if (Number(confirmation_status) === 0) {
                      return <React.Fragment key={idx}></React.Fragment>;
                    }
                    return (
                      <div key={idx} className="noti-wrap">
                        <div className="avatar-box">
                          <span style={{ textTransform: "capitalize" }}>
                            {fname && fname[0]}
                            {lname && lname[0]}
                          </span>
                        </div>
                        <div className="text-box">
                          <p
                            style={{ textTransform: "capitalize" }}
                            className="value"
                          >{`${fname || "---"} ${lname || "---"}`}</p>
                          <p className="label grey-white-color">
                            {user_email || "---"}
                          </p>
                        </div>
                        {/* select input start */}
                        <form autoComplete="off" action="">
                          {" "}
                          <RavenInputField
                            type={`select`}
                            color={`black-light`}
                            value={{
                              label: formatTypeFunction(clearance),
                              value: clearance,
                            }}
                            className={`react-select-team`}
                            disabled={
                              clearance.toLowerCase() === SPECIAL_ROLE_NAME
                                ? true
                                : user?.clearance !== SPECIAL_ROLE_NAME
                                ? true
                                : !user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_change_team_member_role"
                                  )
                                ? true
                                : false
                            }
                            //   loading={true}
                            menuPlacement={
                              rerrangeListFunc(teamMemberList)?.length > 1 &&
                              idx > 1
                                ? "top"
                                : `bottom`
                            }
                            selectOption={formatRoleOption(teamRoles)}
                            selectStyles={reactSelectStyle}
                            style={{ textTransform: "capitalize" }}
                            onChange={(e) => {
                              handleEditRole(e, chi);
                            }}
                          />
                        </form>

                        {/* select input end */}
                        {/* delete box start */}
                        {clearance.toLowerCase() !== SPECIAL_ROLE_NAME &&
                        user?.clearance === SPECIAL_ROLE_NAME &&
                        user_resource_and_permissions?.allowed_permissions?.includes(
                          "can_remove_team_member"
                        ) ? (
                          <div
                            onClick={() => {
                              setSingleChild(chi);
                              setShowModal((prev) => {
                                return { ...prev, team_delete: true };
                              });
                            }}
                            className="delete-box white-black-bg"
                          >
                            <figure className="img-box">
                              <img src={deleteIcon} alt="" className="img" />
                            </figure>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* delete box end */}
                      </div>
                    );
                  }
                )}
                {teamMembers?.pending_team_invites?.map((chi, idx) => {
                  const { first_name, last_name, email } = chi;
                  return (
                    <div key={idx} className="noti-wrap">
                      <div style={{ opacity: ".5" }} className="avatar-box">
                        <span style={{ textTransform: "capitalize" }}>
                          {first_name && first_name[0]}
                          {last_name && last_name[0]}
                        </span>
                      </div>
                      <div style={{ opacity: ".5" }} className="text-box">
                        <p
                          style={{ textTransform: "capitalize" }}
                          className="value"
                        >{`${first_name || "---"} ${last_name || "---"}`}</p>
                        <p className="label grey-white-color">
                          {email || "---"}
                        </p>
                      </div>
                      {/* revinvite and delete invite box start */}
                      {loadingRetry && singleObj?.email === chi?.email ? (
                        <>
                          {" "}
                          <div
                            style={{
                              padding: "0rem",
                              display: "flex",
                              // justifyContent: "flex-end",
                              // transform: "translateX(1.5rem)",
                            }}
                            className="load-wrap"
                          >
                            <ThreeDots
                              height="10"
                              width="80"
                              radius="9"
                              // color="#020202"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="reinvite-delete-invite-box">
                          {/* retry box start */}
                          <>
                            {" "}
                            <div
                              onClick={() => {
                                setSingleObj(chi);
                                // console.log(chi)
                                handleSubmitRetry(chi);
                              }}
                              className="retry-box white-black-bg card-bg tooltip-hover-wrap"
                            >
                              <figure className="img-box">{retryIcon}</figure>
                              <RavenToolTip
                                color={`black-light`}
                                text={`Resend invite to ${lowerCaseWrap(
                                  chi?.first_name
                                )} ${chi?.last_name}`}
                                position={`left`}
                              />
                            </div>
                          </>

                          {/* retry box end */}
                          {/* delete box start */}
                          <>
                            {" "}
                            <div
                              onClick={() => {
                                setSingleObj(chi);
                                handleDeletePending(chi);
                              }}
                              className="delete-box white-black-bg card-bg tooltip-hover-wrap"
                            >
                              <figure className="img-box">
                                <img src={deleteIcon} alt="" className="img" />
                              </figure>
                              <RavenToolTip
                                color={`black-light`}
                                text={`Delete Invite`}
                                position={`top-left`}
                              />
                            </div>
                          </>

                          {/* retry box end */}
                        </div>
                      )}
                      {/* revinvite and delete invite box end */}
                      {/* pending box start */}
                      <div className="pending-box-wrap">
                        <div
                          className={`status-type-wrap status-type-wrap-pending`}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            Pending
                          </span>
                        </div>
                      </div>
                      {/* pending box end */}
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                // className="box-wrap grey-bg"
                style={{
                  display: "grid",
                  placeItems: "center",
                  minHeight: "45rem",
                }}
              >
                {" "}
                <NoContentBox
                  loading={loading}
                  title={`No team Member`}
                  text="Add team member by clicking on the invite team member button above"
                />
              </div>
            )}
            {/* team member end */}

            {/* noti wrap end */}
            {/* noti wrap start */}

            {/* noti wrap end */}
            {/* noti wrap start */}

            {/* noti wrap end */}
          </div>
        </div>
      </DashboardSettingsLayout>
      {/* team invite modal start */}
      <TeamInfoModal
        visible={showModal?.team_invite}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, team_invite: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* team invite modal end */}
      {/* delete team modal start */}
      <DeleteTeamModal
        detail={singleChild}
        visible={showModal?.team_delete}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, team_delete: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete team modal end */}
    </>
  );
};

export default DashboardSettingsTeams;
