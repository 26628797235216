import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "../../styles/dashboard/HeaderNav.css";
import notifyIcon from "../../assets/notify-icon.svg";
// import defaultImg from "../../assets/default-profile-img.svg";
// import defaultImgdark from "../../assets/deafult-dark-profile.svg";
import { FaAngleDown, FaBars } from "react-icons/fa";
import lightIcon from "../../assets/light-theme-icon.svg";
import darkIcon from "../../assets/dark-theme-icon.svg";
import ActionContext from "../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
// import { toggleSidebar } from "../../redux/home";
// import useSound from "use-sound";
import {
  getAllAccounts,
  getAllBusinesses,
  // getAllNotification,
  getUser,
  getUserREsourcesAndPermissions,
  loginIntoAnotherBusiness,
} from "../../redux/user";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import profileIcon from "../../assets/new-profile-setting-icon.svg";
import pinChangeIcon from "../../assets/new-pin-setting-icon.svg";
import settingsIcon from "../../assets/new-setting-setting-icon.svg";
// import addNewBusIcon from "../../assets/add-business-icon.svg";
import supportIcon from "../../assets/profile-verification-icon.svg";
import supportLightIcon from "../../assets/verification-light-icon-profile.svg";
import logoutIcon from "../../assets/new-logout-setting-icon.svg";
import defaultImg from "../../assets/Default_pfp.svg.png";
import ConfirmLogoutModal from "./ConfirmLogoutModal";
import mobileLogo from "../../assets/raven-lone-black.svg";
import mobileLogoWhite from "../../assets/raven_lone_logo.svg";
import ManageTransactionLimitModal from "../../pages/dashboard/profile/ManageTransactionLimitModal";
import accountLimitIcon from "../../assets/Account-limits-01limit-icon-drop.svg";
import CryptoJS from "crypto-js";
import {
  formatMetaData,
  getPrimaryAccount,
  // getSoundToUseFunction,
  lowerCaseWrap,
  reactSelectStyle,
  trimLongString,
} from "../../utils/helper/Helper";
import {
  getDirectorShareholders,
  updateAppearance,
} from "../../redux/settings";
import { getTransactionLimits } from "../../redux/transaction";
// import env from "../../env";
// import { io } from "socket.io-client";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import NotiReuseableBox from "../../components/reusables/NotiReuseableBox";
// import { size } from "lodash";
import useWindowSize from "../../utils/helper/UseWindowSize";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";
// import ConfettiWithSound from "../../components/reusables/ConffetiWithSound";
// import waterDropSound from "../../assets/water_droplet_5-165635.mp3";
// import messageSound from "../../assets/message-with-intro.mp3"
// import useNotificationSound from "../../components/reusables/newSoundReusable";
// import waterDropSound from "../../assets/test-sound.wav";

const HeaderNav = ({
  toggleTheme,
  theme,
  setShowModalMain,
  setShowModalForBusiness,
  onViewNotification,
  showNotify,
  setHideInfo,
}) => {
  const [greetingVal, setGreetingVal] = useState("");
  const [showNotiWrap, setShowNotiwrap] = useState(false);
  const {
    loading,
    user,
    business,
    account_details,
    all_account,
    business_accounts,
    user_resource_and_permissions,
    // global_account,
    bankbox_app_config,
    bankbox_info,
  } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const [logoutload] = useState(false);
  const dispatch = useDispatch();

  // const onToggle = () => dispatch(toggleSidebar());
  // const [toggleCall, setToggleCall] = useState(false);
  useEffect(() => {
    if (
      Object.keys(user).length === 0 ||
      Object?.keys(business).length === 0 ||
      Object?.keys(account_details).length === 0 ||
      all_account?.length < 1 ||
      !window.grecaptcha
    ) {
      // dispatch(getUser());
      dispatch(getTransactionLimits());
      dispatch(getDirectorShareholders());
      uploadLogoToContext();
      dispatch(getAllBusinesses());
      getAllAccountsFunc();
      dispatch(getUserREsourcesAndPermissions());
      // dispatch(getAllAccounts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getAllAllcount = aynsc() => {
  //   const data = await
  // }

  // function onRecaptchaReady() {
  //   setToggleCall(!toggleCall);
  // }
  // window?.grecaptcha?.ready(onRecaptchaReady);

  const uploadLogoToContext = async () => {
    const data = await dispatch(getUser());
    // console.log(data);
    if (data?.payload?.status === "success") {
      actionCtx.setBusinessLogo(data?.payload?.data.business?.business_logo);
    }
  };

  useEffect(() => {
    var CurrentDate = moment().format("HH");
    const numVal = Number(CurrentDate);
    const value =
      numVal >= 0 && numVal <= 11
        ? "😇 Good Morning,"
        : numVal >= 12 && numVal < 16
        ? "🌞 Good Afternoon,"
        : "😴 Good Evening,";
    setGreetingVal(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const size = useWindowSize();
  // const restructureName = (name) => {
  //   let val;
  //   const nameSplit = name?.split(" ");
  //   if (nameSplit?.length > 3) {
  //     const newVal = `${nameSplit[0]} ${nameSplit[1]} ${nameSplit[2]} .....`;
  //     val = newVal;
  //     // eslint-disable-next-line no-unused-expressions
  //   } else {
  //     val = name;
  //   }
  //   return val;
  // };
  // const SERVER = env.base_url;
  // const authenticated = localStorage.getItem("token");
  // // const dispatch = useDispatch();
  // const decryptTokenFunc = (tok) => {
  //   const encryptedToken = tok;
  //   const secretKey =
  //     "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

  //   // Encrypt the token
  //   const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //   const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  //   // console.log(tok);
  //   // console.log(decryptedToken);
  //   return decryptedToken;
  // };

  const formSelectList = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const newList = list?.filter(
        (chi) => Number(chi?.confirmation_status) === 1
      );
      //  console.log(newList);
      return newList;
    }
  };

  const getAllAccountsFunc = async () => {
    const data = await dispatch(getAllAccounts());
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      const account = data?.payload?.data?.data?.account;
      // console.log(account);
      account?.length < 1 && setHideInfo();
      if (account?.length > 0) {
        const checkId = localStorage.getItem("current_sub_account_id");
        // console.log(checkId);
        if (checkId) {
          const newObj = account?.find(
            (chi) => Number(chi?.id) === Number(checkId)
          );
          actionCtx.setCurrentAccount(newObj);
        } else {
          //  console.log(getPrimaryAccount(account));
          if (Object?.keys(getPrimaryAccount(account))?.length > 0) {
            // console.log(getPrimaryAccount(account));
            localStorage.setItem(
              "current_sub_account_id",
              getPrimaryAccount(account)?.id
            );
            actionCtx.setCurrentAccount(getPrimaryAccount(account));
          } else {
            // console.log(account, "--------------------");
            actionCtx.setCurrentAccount(account[0]);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   // console.log(all_account);
  //   // if (all_account?.length < 1) {
  //   //   actionCtx.setCurrentAccount(getPrimaryAccount(all_account));
  //   // }
  //   if (Object?.keys(actionCtx?.currentAccountDrop)?.length < 1) {
  //     actionCtx.setCurrentAccount(getPrimaryAccount(all_account));
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [all_account]);

  const navigate = useNavigate();
  const profileIconTwo = (
    <svg
      width="24"
      className="img"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const changePinIconTwo = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M12 12H12.005M17 12H17.005M7 12H7.005M5.2 7H18.8C19.9201 7 20.4802 7 20.908 7.21799C21.2843 7.40973 21.5903 7.71569 21.782 8.09202C22 8.51984 22 9.0799 22 10.2V13.8C22 14.9201 22 15.4802 21.782 15.908C21.5903 16.2843 21.2843 16.5903 20.908 16.782C20.4802 17 19.9201 17 18.8 17H5.2C4.0799 17 3.51984 17 3.09202 16.782C2.71569 16.5903 2.40973 16.2843 2.21799 15.908C2 15.4802 2 14.9201 2 13.8V10.2C2 9.0799 2 8.51984 2.21799 8.09202C2.40973 7.71569 2.71569 7.40973 3.09202 7.21799C3.51984 7 4.0799 7 5.2 7ZM12.25 12C12.25 12.1381 12.1381 12.25 12 12.25C11.8619 12.25 11.75 12.1381 11.75 12C11.75 11.8619 11.8619 11.75 12 11.75C12.1381 11.75 12.25 11.8619 12.25 12ZM17.25 12C17.25 12.1381 17.1381 12.25 17 12.25C16.8619 12.25 16.75 12.1381 16.75 12C16.75 11.8619 16.8619 11.75 17 11.75C17.1381 11.75 17.25 11.8619 17.25 12ZM7.25 12C7.25 12.1381 7.13807 12.25 7 12.25C6.86193 12.25 6.75 12.1381 6.75 12C6.75 11.8619 6.86193 11.75 7 11.75C7.13807 11.75 7.25 11.8619 7.25 12Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const accountLimitIconTwo = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M13 5C13 6.10457 10.5376 7 7.5 7C4.46243 7 2 6.10457 2 5M13 5C13 3.89543 10.5376 3 7.5 3C4.46243 3 2 3.89543 2 5M13 5V9.45715C11.7785 9.82398 11 10.3789 11 11M2 5V17C2 18.1046 4.46243 19 7.5 19C8.82963 19 10.0491 18.8284 11 18.5429V11M2 9C2 10.1046 4.46243 11 7.5 11C8.82963 11 10.0491 10.8284 11 10.5429M2 13C2 14.1046 4.46243 15 7.5 15C8.82963 15 10.0491 14.8284 11 14.5429M22 11C22 12.1046 19.5376 13 16.5 13C13.4624 13 11 12.1046 11 11M22 11C22 9.89543 19.5376 9 16.5 9C13.4624 9 11 9.89543 11 11M22 11V19C22 20.1046 19.5376 21 16.5 21C13.4624 21 11 20.1046 11 19V11M22 15C22 16.1046 19.5376 17 16.5 17C13.4624 17 11 16.1046 11 15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const verificationIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="img"
    >
      <path
        className="dull-bright-filter"
        d="M17 10V8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8V10M12 14.5V16.5M8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C17.7202 10 16.8802 10 15.2 10H8.8C7.11984 10 6.27976 10 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const settingIconTwo = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M15.0505 9H5.5C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4H15.0505M8.94949 20H18.5C19.8807 20 21 18.8807 21 17.5C21 16.1193 19.8807 15 18.5 15H8.94949M3 17.5C3 19.433 4.567 21 6.5 21C8.433 21 10 19.433 10 17.5C10 15.567 8.433 14 6.5 14C4.567 14 3 15.567 3 17.5ZM21 6.5C21 8.433 19.433 10 17.5 10C15.567 10 14 8.433 14 6.5C14 4.567 15.567 3 17.5 3C19.433 3 21 4.567 21 6.5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const switchAccountIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M20 17H4M4 17L8 13M4 17L8 21M4 7H20M20 7L16 3M20 7L16 11"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const addNewBusIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M12 8V16M8 12H16M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const signOutIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // className="dull-bright-filter"
        d="M16 17L21 12M21 12L16 7M21 12H9M12 17C12 17.93 12 18.395 11.8978 18.7765C11.6204 19.8117 10.8117 20.6204 9.77646 20.8978C9.39496 21 8.92997 21 8 21H7.5C6.10218 21 5.40326 21 4.85195 20.7716C4.11687 20.4672 3.53284 19.8831 3.22836 19.1481C3 18.5967 3 17.8978 3 16.5V7.5C3 6.10217 3 5.40326 3.22836 4.85195C3.53284 4.11687 4.11687 3.53284 4.85195 3.22836C5.40326 3 6.10218 3 7.5 3H8C8.92997 3 9.39496 3 9.77646 3.10222C10.8117 3.37962 11.6204 4.18827 11.8978 5.22354C12 5.60504 12 6.07003 12 7"
        stroke="#FF0F00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const dropList = [
    {
      name: "View profile",
      icon: profileIcon,
      link: "/dashboard-profile",
      iconTwo: profileIconTwo,
      onClick: () => {
        navigate("/dashboard-profile");
      },
    },
    {
      name: "Change PIN",
      icon: pinChangeIcon,
      link: "",
      iconTwo: changePinIconTwo,
      // onClick: () => {
      //   navigate("/dashboard-profile")
      // },
    },
    {
      name: "Account Limits",
      icon: accountLimitIcon,
      iconTwo: accountLimitIconTwo,
      onClick: () => {
        setShowModal((prev) => {
          return { ...prev, limit: true };
        });
      },
    },
    {
      name: "Verification",
      icon: theme === "dark" ? supportLightIcon : supportIcon,
      link: "/dashboard-verification",
      iconTwo: verificationIcon,
      onClick: () => {
        navigate("/dashboard-verification");
      },
    },
    {
      name: "Settings",
      icon: settingsIcon,
      link: "/dashboard-settings-notification",
      iconTwo: settingIconTwo,
      onClick: () => {
        navigate("/dashboard-settings-notification");
      },
    },
    {
      name:
        formSelectList(business_accounts)?.length > 1
          ? "Switch Business"
          : "Create new business",
      icon:
        formSelectList(business_accounts)?.length < 2
          ? addNewBusIcon
          : settingsIcon,
      link: "",
      iconTwo:
        formSelectList(business_accounts)?.length < 2
          ? addNewBusIcon
          : switchAccountIcon,
    },
    {
      name: "Logout",
      icon: logoutIcon,
      link: "",
      iconTwo: signOutIcon,
      onClick: () => {
        setShowModal((prev) => {
          return { ...prev, logout: true };
        });
      },
    },
  ];
  const [showModal, setShowModal] = useState({
    logout: false,
    limit: false,
  });

  const handleSideNavUpdate = async (obj) => {
    const data = await dispatch(updateAppearance(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // localStorage?.setItem("sidebar-theme-style", obj?.side_nav_style);
      dispatch(getUser());
    }
  };

  useEffect(() => {
    // console.log(formatMetaData(user?.theme_display_settings)?.table_size);
    actionCtx?.setSideBarNav(
      formatMetaData(user?.theme_display_settings)?.side_nav_style
    );
    if (formatMetaData(user?.theme_display_settings)?.table_size) {
      actionCtx?.setPerPage({
        label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
      });
    } else {
      actionCtx?.setPerPage({ label: "50", value: 50 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [businessAccountVal, setBusinessAcountVal] = useState("");
  // const [loadingVerify, setloadingVerify] = useState(false);
  const formatBusonessList = (list) => {
    // console.log(list);
    // console.log(business);
    if (list?.length > 0) {
      // const newList = list?.filter(
      //   (chi) => Number(chi?.business_id) !== Number(business?.id)
      // );
      const newList = list?.map((chi) => {
        return { ...chi, label: chi?.business_name, value: chi?.business_id };
      });
      return newList;
    }
  };

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const handleSwitchBusiness = async (e) => {
    // e.preventDefault();
    const obj = {
      business_id: String(e.business_id || e?.value),
    };
    // console.log(obj);
    // return
    actionCtx?.setShowGeneralLoader(true);
    const data = await dispatch(loginIntoAnotherBusiness(obj));
    if (data?.payload?.data?.status === "success") {
      // actionCtx?.setShowGeneralLoader(false);
      const token = data?.payload?.data?.data?.token;
      localStorage?.setItem("token", encryptTokenFunc(token));
      window.location.reload();
    } else {
      actionCtx?.setShowGeneralLoader(false);
    }
  };

  const testingPreviewNotiRef = useOnClickOutside(() => {
    setShowNotiwrap(false);
  });

  const checkListFilter = (list) => {
    let val;
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_verify_business_account"
      )
    ) {
      const newList = list?.filter((chi) => chi?.name !== "Verification");
      // console.log(newList);
      val = newList;
    } else {
      val = list;
    }

    return val;
  };

  return (
    <>
      <div className="header-nav-wrap">
        {/* menu icon box start */}
        <div
          onClick={() => {
            actionCtx?.setMobileSlide(true);
          }}
          className="menu-icon-box"
        >
          <FaBars className="icon black-white-color" />
        </div>
        {/* menu icon box end */}
        {/* mobile logo start */}
        <div className="mobile-logo-box">
          <figure className="img-box">
            <img
              src={theme === "dark" ? mobileLogoWhite : mobileLogo}
              alt=""
              className="img dull-bright-filter"
            />
          </figure>
        </div>
        {/* mobile logo end */}
        {/* greeting box start */}
        <div
          onClick={() => {
            actionCtx?.setShowProfileDrop("close");
            console.log(bankbox_app_config);
            console.log(bankbox_info);

            // console.log(global_account);
            // console.log(actionCtx?.currentAccount);
            // console.log(business);
            // console.log(all_account);
            // console.log(account_details);
            // console.log(user);
            // console.log(formatMetaData(user?.theme_display_settings));
            // playNotification()
            // console.log(user_resource_and_permissions);
            // console.log(business_accounts);
            // console.log(window.grecaptcha);
            // console.log(window.fcWidget);
          }}
          className="greeting-box"
        >
          <div
            style={{ display: "flex", alignItems: "center", gap: ".3rem" }}
            className="text"
          >
            <span className="grey-white-color">
              {greetingVal || "😇 Good Morning,"}
            </span>{" "}
            <>
              {loading ? (
                <div
                  style={{
                    alignSelf: "center",
                    marginLeft: ".5rem",
                    display: "grid",
                    placeItems: "center",
                  }}
                  className=""
                >
                  <Skeleton width={70} height={15} />
                </div>
              ) : (
                <span
                  className="black-white-color"
                  style={{
                    textTransform: "capitalize",
                    marginBottom: "0rem",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {user?.fname}
                </span>
              )}
            </>
          </div>
        </div>
        {/* greeting box end */}
        {/* theme box start */}
        <div className="theme-box">
          <div
            onClick={() => {
              theme === "dark" && toggleTheme("light");
              actionCtx.setToggleTheme();
              const newObj = {
                ...formatMetaData(user?.theme_display_settings),
                theme: "light",
              };
              handleSideNavUpdate(newObj);
            }}
            className={`box light-box ${
              theme !== "dark" && "box-toggle-theme-active"
            }`}
          >
            <figure className="img-box">
              <img src={lightIcon} alt="" className="img dull-bright-filter" />
            </figure>
            <p className="text">Light</p>
          </div>
          {/* end of light box */}
          <div
            onClick={() => {
              theme === "light" && toggleTheme("dark");
              actionCtx.setToggleTheme();
              const newObj = {
                ...formatMetaData(user?.theme_display_settings),
                theme: "dark",
              };
              handleSideNavUpdate(newObj);
            }}
            className={`box light-box ${
              theme === "dark" && "box-toggle-theme-active"
            }`}
          >
            <figure className="img-box">
              <img src={darkIcon} alt="" className="img dull-bright-filter" />
            </figure>
            <p className="text">Dark</p>
          </div>
          {/* end of dark box */}
        </div>
        {/* theme box end */}
        {/* notify box start */}
        {/* <div className="test-wrap"></div> */}
        <div
          ref={testingPreviewNotiRef}
          className="notify-box "
          style={{ cursor: "pointer" }}
        >
          <div
            onClick={() => {
              size.width > 900
                ? setShowNotiwrap(!showNotiWrap)
                : onViewNotification();
              // console.log(actionCtx.allNotifications);
            }}
            className="notify-box-two white-black-bg  border-thin"
          >
            {showNotify && <span className="red-dot"></span>}
            <figure className="img-box-icon">
              <img src={notifyIcon} alt="" className="img dull-bright-filter" />
            </figure>
            {/* notify laptop drop start */}
          </div>

          {showNotiWrap && (
            <div className="notification-big-screen-drop shadow-shadow-box-shadow">
              <NotiReuseableBox
                onClose={() => {
                  setShowNotiwrap(false);
                }}
                typeTwo
              />
            </div>
          )}
          {/* notify laptop drop end */}
        </div>
        {/* notify box end */}
        {/* profile and drop wrap start */}
        <div className="profile-and-drop-wrap">
          {/* profile wrap start */}
          <div
            onClick={() => {
              actionCtx?.setShowProfileDrop("show");
            }}
            className="profile-wrap border-profile"
          >
            <figure
              onClick={() => {
                actionCtx?.setShowProfileDrop("show");
              }}
              className="img-box"
            >
              <img
                src={
                  business?.business_logo ||
                  actionCtx?.businessLogo ||
                  defaultImg
                }
                alt=""
                className="img"
              />
            </figure>
            <p
              onClick={() => {
                actionCtx?.setShowProfileDrop("show");
              }}
              className="name"
            >
              {loading ? (
                <div
                  style={{
                    alignSelf: "center",
                    marginLeft: ".5rem",
                    display: "grid",
                    placeItems: "center",
                    textTransform: "capitalize",
                  }}
                  className=""
                >
                  <Skeleton width={80} height={15} />
                </div>
              ) : (
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  {trimLongString(business?.business_name, 23)}
                </span>
              )}
            </p>
            <FaAngleDown
              onClick={() => {
                actionCtx?.setShowProfileDrop("show");
              }}
              className="icon"
            />
          </div>
          {/* profile wrap end */}
          {/* drop down profile start */}
          {actionCtx?.showProfileDrop && (
            <div className="drop-box-wrap card-bg">
              {checkListFilter(dropList)?.map((chi, idx) => {
                const { icon, name, iconTwo } = chi;

                if (name === "Change PIN") {
                  return (
                    <React.Fragment key={idx}>
                      {user?.pin_status === 1 ? (
                        <div
                          onClick={() => {
                            name === "Change PIN" &&
                              actionCtx?.setShowUpdate(true);
                            actionCtx?.setShowProfileDrop("close");
                          }}
                          className="child-row"
                        >
                          <div className="img-wrap">
                            <figure className="img-box">
                              {iconTwo ? (
                                <>{iconTwo}</>
                              ) : (
                                <img src={icon} alt="" className="img" />
                              )}
                            </figure>
                          </div>
                          {name === "Logout" && logoutload ? (
                            <div style={{ margin: "0rem" }}>
                              <ThreeDots
                                height="10"
                                width="65"
                                radius="9"
                                color="#020202"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <p className="text">{name}</p>
                          )}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            actionCtx?.setTrxPin(true);
                            // setShowModalMain((prev) => {
                            //   return { ...prev, set_pin: true };
                            // });
                            actionCtx?.setShowProfileDrop("close");
                          }}
                          className="child-row"
                        >
                          <div className="img-wrap">
                            <figure className="img-box">
                              {iconTwo ? (
                                <>{iconTwo}</>
                              ) : (
                                <img src={icon} alt="" className="img" />
                              )}
                            </figure>
                          </div>
                          {name === "Logout" && logoutload ? (
                            <div style={{ margin: "0rem" }}>
                              <ThreeDots
                                height="10"
                                width="65"
                                radius="9"
                                color="#020202"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <p className="text">Set PIN</p>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  );
                }
                if (
                  !user_resource_and_permissions?.allowed_permissions?.includes(
                    "can_create_admin_pin"
                  ) &&
                  chi === "Change PIN"
                ) {
                  return <React.Fragment key={idx}></React.Fragment>;
                }
                if (
                  !user_resource_and_permissions?.allowed_permissions?.includes(
                    "can_verify_business_account"
                  ) &&
                  chi === "Verification"
                ) {
                  return <React.Fragment key={idx}></React.Fragment>;
                }

                return (
                  <div
                    onClick={() => {
                      // console.log(
                      //   user_resource_and_permissions?.allowed_permissions
                      // );
                      if (chi?.onClick) {
                        chi?.onClick();
                      } else {
                        name === "Switch Business" &&
                          formSelectList(business_accounts)?.length > 1 &&
                          setShowModalForBusiness();
                        name === "Create new business" &&
                          formSelectList(business_accounts)?.length < 2 &&
                          actionCtx.createBusinessFunc(true);
                      }
                      // link && navigate(link);
                      // name === "Logout" &&
                      //   setShowModal((prev) => {
                      //     return { ...prev, logout: true };
                      //   });
                      // name === "Account Limits" &&
                      //   setShowModal((prev) => {
                      //     return { ...prev, limit: true };
                      //   });
                      actionCtx?.setShowProfileDrop("close");
                      // name === "" &&
                    }}
                    key={idx}
                    className="child-row"
                  >
                    <div className="img-wrap">
                      <figure className="img-box">
                        {iconTwo ? (
                          <>{iconTwo}</>
                        ) : (
                          <img src={icon} alt="" className="img" />
                        )}
                      </figure>
                    </div>
                    {name === "Logout" && logoutload ? (
                      <div style={{ margin: "0rem" }}>
                        <ThreeDots
                          height="10"
                          width="65"
                          radius="9"
                          color="#020202"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <p
                        style={{
                          color: name === "Logout" && "rgba(255, 15, 0, 1)",
                        }}
                        className="text"
                      >
                        {name === "Switch Business" &&
                        business_accounts?.length < 2
                          ? "Create new business"
                          : name}
                      </p>
                    )}
                  </div>
                );
              })}
              {/* switch business start */}
              {business_accounts?.length < -1 && (
                <div
                  style={{ width: "100%", position: "relative" }}
                  className="switch-business-box"
                >
                  <RavenInputField
                    type="select"
                    placeholder="Select accout"
                    color="black-light"
                    selectStyles={reactSelectStyle}
                    className="business-select-switch"
                    selectOption={formatBusonessList(business_accounts)}
                    value={
                      businessAccountVal || {
                        label: lowerCaseWrap(business?.business_name),
                        value: business?.id,
                      }
                    }
                    onChange={(e) => {
                      if (business?.business_name !== e?.business_name) {
                        handleSwitchBusiness(e);
                        setBusinessAcountVal(e);
                      }
                    }}
                  />
                  {/* loading start */}
                  {/* {
                    <>
                      {loadingVerify ? (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "100%",
                            transform: "translateY(-50%) translateX(1.5rem)",
                            right: "0",
                          }}
                          className="load-wrap"
                        >
                          <ThreeDots
                            height="10"
                            width="80"
                            radius="9"
                            // color="#020202"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  } */}
                  {/* loading end */}
                </div>
              )}
              {/* switch business end */}
            </div>
          )}
          {/* drop down profile end */}
        </div>
        {/* profile and drop wrap end */}
      </div>
      {/* change pin start */}
      <ManageTransactionLimitModal
        visible={showModal?.limit}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, limit: false };
          });
        }}
      />
      {/* change pin end */}
      <ConfirmLogoutModal
        visible={showModal?.logout}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, logout: false };
          });
        }}
      />
    </>
  );
};

export default HeaderNav;
